/* eslint-env browser */

import assignItemPug from "../../dialogs/assignItem/assignItem.pug";
import {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class InboxItemController {
  constructor(
    $scope,
    $timeout,
    Config,
    Authentication,
    EventService,
    StripService,
    Markers,
    $mdDialog,
    StudyService,
    UserService,
    InboxItemService,
    ReportService
  ) {
    this._$timeout = $timeout;
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._Authentication = Authentication;
    this._EventService = EventService;
    this._StripService = StripService;
    this._Markers = Markers;
    this._StudyService = StudyService;
    this._UserService = UserService;
    this._InboxItemService = InboxItemService;
    this._ReportService = ReportService;

    this.features = Config.features;
    this.selectedUserRhythmClassification = "";

    this.technicianFindings = "";
    this.meetsMdnCriteria = false;
    this.createdBeatMarkers = [];
    this.deletedBeatMarkers = [];
  }

  /// Public Functions ///
  get isItemOpen() {
    return (
      this.item &&
      this.selectedItem &&
      // Item ID and Type must match since items in different tables can have the same ID
      this.item.id === this.selectedItem.id &&
      // When the next Available Item is opened, the corresponding item on the page has to remain closed.
      this.selectedItem.isNextAvailable === this.isNextAvailable
    );
  }

  get itemClass() {
    return {
      clickedCardTitle: this.isItemOpen,
      unopenableCard: this.item.isUnopenable,
    };
  }

  get patientName() {
    // Don't display patient name on Triage
    if (this.item.isTriageItem) {
      return this.item.tzSerial;
    }

    return this.item.patientName || this.item.tzSerial;
  }

  getDisplayedTime(datetime) {
    return formatDateAndTime({datetime, zone: this.item.study?.timeZone});
  }

  async clickedItem() {
    // Don't select an item if we are already selecting one
    if (!this._InboxItemService.isSelectingItem && !this._blockClick) {
      try {
        this.item.loading = true;
        this._blockClick = true;
        this.item.blockClick = true;

        // Blocks clicking the card more frequently than 1x per second (was causing issues with locking)
        // this setTimeout passes the parameter this.item, to make sure the correct item is being modified.
        setTimeout(() => {
          this._blockClick = false;
          this.item.blockClick = false;
          this._$scope.$apply();
        }, 1000);

        this.selectedItem = await this._InboxItemService.selectItem(this.item);
        if (this.selectedItem) {
          this.selectedItem.isNextAvailable = false;
          Object.assign(this.item, this.selectedItem, {loading: true});
          this._$scope.$apply();
        }

        this.item.loading = false;
      } catch (err) {
        this.item.loading = false;
        this._$scope.$apply();

        /* Do Nothing, Don't open the card or update its information */
        if (err.status === 403) {
          const popupTitle = "Viewing maximum allowed items";
          const errorMessage =
            "You have too many items open. Please close other open items in order to view this item. " +
            "If you think this is a mistake, please log out and log back in.";
          this._displayErrorMessage(popupTitle, errorMessage);
        }
        console.error(err);
      }
    }

    return this.item;
  }

  displayMissingSettingsWarning(item) {
    let message = null;

    if (
      item.pendingSettingsDownload &&
      typeof item.studyId === "number" &&
      !["holter", "extendedHolter"].includes(item.studyType) &&
      item.category === "ECG Event"
    ) {
      message = `${item.category || "Inbox Item"} detected with unconfigured study settings`;
    }

    return message;
  }

  displayActionButton(item) {
    return (
      this.isItemOpen &&
      typeof item.studyId === "number" &&
      !item.isTriageItem &&
      !["holter", "extendedHolter"].includes(item.studyType)
    );
  }

  clickedDisplayActions($event, inboxItem) {
    return this._showActionsDialog($event, inboxItem)
      .then(() => {})
      .catch(() => {
        /* do nothing on dialog cancel */
      });
  }

  getStudyTypeIfNeeded(item) {
    if (item.category === "Report") {
      return ` - ${this._StudyService.getDisplayedStudyType(item.studyType)}`;
    }
    return "";
  }

  getBpmIfNeeded(item) {
    if (item.eventType === "rateChange" && item.bpm) {
      return ` - ${item.bpm} BPM`;
    }
    return "";
  }

  async saveChangesOnItem(event, endingAction) {
    try {
      if (["ECG Event", "Report"].includes(this.item.category)) {
        const propertiesToUpdate = {
          technicianFindings: this.technicianFindings,
          meetsMdnCriteria: this.meetsMdnCriteria,
        };

        if (!this.features.saveInProgressChanges) {
          delete propertiesToUpdate.technicianFindings;
          delete propertiesToUpdate.meetsMdnCriteria;
        }

        if (endingAction === "markAsCompleted") {
          propertiesToUpdate.completed = true;
        }

        // save the current changes of the item and then deselect the item
        if (this.item.category === "ECG Event") {
          propertiesToUpdate.userClassification = this.selectedUserRhythmClassification;

          await this._EventService.updateEvent(this.item, propertiesToUpdate);

          // Sort the strip list by intended order
          this._StripService.orderStripList(this.item.eventStrips, "order");

          // Prepare strips for updating their order
          // * set each stripOrder property by index, the order property could be wrong after unsaved strips are filter out
          const stripsToUpdate = this.item.eventStrips
            .filter((strip) => !!strip.id)
            .map(({id}, index) => ({id, properties: {stripOrder: index + 1}}));

          if (stripsToUpdate.length > 0) {
            // update the order of the strips on the event
            await this._StripService.updateStrips(stripsToUpdate);
          }

          if (this.createdBeatMarkers.length !== 0) {
            await this._Markers.createEventBeatMarkers(this.item, this.createdBeatMarkers);
          }

          if (this.deletedBeatMarkers.length !== 0) {
            await Promise.all(
              this.deletedBeatMarkers.map((beatMarker) => {
                return this._Markers.deleteEventBeatMarker(beatMarker.id);
              })
            );
          }
        } else {
          await this._ReportService.updateReport(this.item, propertiesToUpdate);
        }
        await this._InboxItemService.deselectItem();
      }
      // default for notification items, do action selected
      else if (endingAction === "markAsCompleted") {
        await this._InboxItemService.complete();
      } else {
        await this._InboxItemService.deselectItem();
      }

      this.item.loading = false;
    } catch (err) {
      this.item.loading = false;
      if (endingAction === "markAsCompleted") {
        this._displayErrorMessage("Failed to complete item", "Unable to complete the item.", err);
      } else {
        this._displayErrorMessage("Failed to save changes", "Unable to save changes for the item.", err);
      }
      console.error(err);
    }
  }

  clickedAssignToUser(event) {
    return this._$mdDialog
      .show({
        controller: "AssignItemController",
        controllerAs: "assignItem",
        template: assignItemPug(),
        targetEvent: event,
        locals: {item: this.selectedItem},
      })
      .catch((err) => {
        if (err !== "Closed assign item dialog") {
          throw err;
        }
      });
  }

  /// Private Functions ///

  _displayErrorMessage(title, message, error) {
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(title)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${message}</p>`
        )
        .ok("Ok")
    );
  }
}
